<template>
  <div :style="style">
    <slot />
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import { computed } from 'vue';
import type { Asset } from '@/types/storyblok';
import { useStoryblokImageService } from '@/utils/storyblok/image';

const props = defineProps({
  image: { type: Object as PropType<Asset> },
});

const style = computed(() => ({
  backgroundImage: `url(${useStoryblokImageService(props.image?.filename as string)})`,
}));
</script>
